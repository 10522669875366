import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default class Impressum extends React.Component {
  render() {
    const content = this.props.data.content.json;
    return (
      <Layout location={this.props.location}>
        <SEO title={"Impressum"}/>
        <div style={{ padding: 30, minHeight: "100vh" }}>
          {documentToReactComponents(content)}
        </div>
      </Layout>
    );
  }

}

export const pageQuery = graphql`
    query ImpressumQuery{
        content: contentfulImpressumContentRichTextNode {
            json
        }
    }
`;
