import React, { Fragment } from "react";
import Navigation from "./navigation";
import { Link } from "gatsby";

if (typeof window !== "undefined") {
  require("smooth-scroll")("a[href*=\"#\"]");
}

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    let boxShadow = "0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12)";

    return (
      <Fragment>
        <Navigation/>
        <div style={{ maxWidth: 1200, margin: "auto", boxShadow: boxShadow, background: "#FAF5F3" }}>
          <main>{children}</main>
          <footer style={{ padding: 0, margin: 0 }}>
            <Link to={"/impressum"} style={{ color: "black" }}>Impressum</Link>
          </footer>
        </div>
      </Fragment>
    );
  }
}

export default Layout;
