import React, { Fragment } from "react";
import { Link } from "gatsby";
import styles from "./navigation.module.scss";

export default () => (
  <Fragment>
    <header role="navigation">
      <div className={styles.headerMain}>
        <div className={styles.navigation}>
          <div className={styles.navigationItem}>
            <Link to="/#aktuelles">Aktuelles</Link>
          </div>
          <div className={styles.navigationItem}>
            <Link to="/#termine">Termine</Link>
          </div>
          <div className={styles.navigationItem}>
            <Link to="/#vita">Vita</Link>
          </div>
          <div className={styles.navigationItem}>
            <Link to="/#kontakt">Kontakt</Link>
          </div>
        </div>
      </div>
    </header>
  </Fragment>
)
